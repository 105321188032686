import React from 'react';
import { graphql, Link } from 'gatsby';

import styles from './blog-post.module.scss';
import Layout from '../components/layout.js';
import SEO from '../components/seo.js';

const constructAlert = (html) => {
  const alertMatch = /<p><strong>Note:<\/strong> .+?(?=<\/p?)<\/p>/gis;
  let alertText = html.match(alertMatch)

  if (!alertText) {
    return html;
  }

  alertText = alertText[0].replace(/<strong>Note:<\/strong>/g, '');

  return html.replace(
    alertMatch,
    `
    <hx-alert class="my-5" persist status="note" type="info">
      ${alertText}
    </hx-alert>
  `
  );
};

const format = (html) => {
  return constructAlert(html);
};

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;

  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <nav className="hxBreadcrumb">
        <Link to="/">Support</Link>
        <hx-icon class="delimiter" type="angle-right"></hx-icon>
        <Link to="/">How-to Articles</Link>
        <hx-icon class="delimiter" type="angle-right"></hx-icon>
        <Link to={`/support/how-to/${post.frontmatter.permalink}`}>{post.frontmatter.title}</Link>
      </nav>
      <article>
        <header className="mt-5 mb-10">
          <h1>How-to Articles</h1>
          <h2 className="mb-2 mt-5">{post.frontmatter.title}</h2>
          <div className="flex">
            <span className="mr-8">Last updated on: {post.frontmatter.last_modified_date}</span>
            <span>Authored by: {post.frontmatter.created_by}</span>
          </div>
        </header>
        <section
          className={styles.section}
          dangerouslySetInnerHTML={{ __html: format(post.html) }}
        />
      </article>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        permalink
        last_modified_date
        created_by
      }
    }
  }
`;
